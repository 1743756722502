import _ from 'lodash';
import { DS_NAME, CONSOLIDATED_PARENTS, BRANCH_PARENT, FUND_PARENTS } from './shared';
import { DUMMY_LEI_ID } from '../../../../../common/const/lei-products';
import { LOCAL_LANGUAGE_COUNTRIES_MAP } from '../../../../../common/const/countries-local_language';

export function mutateAccountingStandards(data, parent) {
  const info = _.get(
    data,
    [
      DS_NAME,
      'data',
      'relationships',
      parent,
      CONSOLIDATED_PARENTS.includes(parent) && `${DS_NAME}.ParentInformation`
    ].filter(Boolean)
  );
  /* istanbul ignore if */
  if (!info) return;

  const standard = info['accountingStandards'];
  /* istanbul ignore else */
  if (_.isString(standard)) _.set(info, ['accountingStandards', 'accountingStandard', 0, 'standard'], standard);
  else delete info['accountingStandards'];
}

const EXTRANEOUS_SPACES = /\s+|\u00A0/g;

export function trimStrings(data) {
  if (!(data && typeof data == 'object')) return;

  if (Array.isArray(data)) {
    for (let k = data.length - 1; k >= 0; k--) {
      if (typeof data[k] != 'string') {
        trimStrings(data[k]);
        continue;
      }
      const v = data[k].replace(EXTRANEOUS_SPACES, ' ').trim();
      if (v) data[k] = v;
      else data.splice(k, 1);
    }
    return;
  }

  for (const k of Object.keys(data)) {
    if (typeof data[k] != 'string') {
      trimStrings(data[k]);
      continue;
    }
    const v = data[k].replace(EXTRANEOUS_SPACES, ' ').trim();
    if (v) data[k] = v;
    else delete data[k];
  }
}

const removeFundParentsWhenEntityIsNotFund = payload => {
  if (payload && payload[DS_NAME].data.entity.entityCategory !== 'FUND') {
    FUND_PARENTS.forEach(fundParent => {
      delete payload[DS_NAME].data.relationships[fundParent];
    });
  }
};

const removeDummyBranchFields = payload => {
  if (payload) delete payload[DS_NAME].data.entity.branchCountry && delete payload[DS_NAME].data.entity.branchRegion;
};

const removeDummyFundSubCategory = payload => {
  if (payload) delete payload[DS_NAME].data.entity.fundSubCategory;
};

const cleanManagedFundParent = payload => {
  const parentPath = [DS_NAME, 'data', 'relationships', 'managingFundParent'];
  const isSelfManaged = _.get(payload, [...parentPath, 'isSelfManaged']);

  if (isSelfManaged === 'THE_FUND_ITSELF_(NO_MANAGEMENT_COMPANY)' && !_.get(payload, [...parentPath, 'parentLei'])) {
    _.set(payload, [...parentPath, 'parentLei'], DUMMY_LEI_ID);
  }

  if (_.get(payload, [DS_NAME, 'data', 'relationships', 'managingFundParent'])) {
    delete payload[DS_NAME].data.relationships.managingFundParent.isSelfManaged;
  }
};

const removeDummyNoCreationDateIsAvailable = payload => {
  delete payload[DS_NAME].data.entity.noCreationDateIsAvailable;
};

const cleanConsolidatedParentsInfo = payload => {
  for (const parentType of ['directParent', 'ultimateParent']) {
    const parentEntityPath = [
      DS_NAME,
      'data',
      'relationships',
      parentType,
      `${DS_NAME}.ParentInformation`,
      'parentEntity'
    ];
    const parentEntity = _.get(payload, parentEntityPath);
    if (parentEntity && parentEntity.lei) {
      _.set(payload, parentEntityPath, {
        [`${DS_NAME}.ParentLEI`]: { ...parentEntity }
      });
    }
  }
};

const moveDummyLanguageAddressesToOtherAddresses = payload => {
  const entityCategory = _.get(payload[DS_NAME], ['data', 'entity', 'entityCategory']);
  const jurisdictionCountry = _.get(payload[DS_NAME], ['data', 'entity', 'legalJurisdictionCountry']);
  const localLanguageCountries = Object.keys(LOCAL_LANGUAGE_COUNTRIES_MAP);
  const legalAddressInLocalLanguage = _.get(payload[DS_NAME], ['data', 'entity', 'legalAddressInLocalLanguage']);
  const headquartersAddressInLocalLanguage = _.get(payload[DS_NAME], [
    'data',
    'entity',
    'headquartersAddressInLocalLanguage'
  ]);
  const headquartersAddressCountry = _.get(headquartersAddressInLocalLanguage, ['address', 'country']);

  // early return if it's a branch or not a target country for local language - non-creates
  if (
    entityCategory == 'BRANCH' ||
    (!localLanguageCountries.includes(jurisdictionCountry) &&
      !localLanguageCountries.includes(headquartersAddressCountry))
  ) {
    legalAddressInLocalLanguage && delete payload[DS_NAME].data.entity.legalAddressInLocalLanguage;
    headquartersAddressInLocalLanguage && delete payload[DS_NAME].data.entity.headquartersAddressInLocalLanguage;
    return;
  }

  const otherAddresses = _.get(
    payload[DS_NAME],
    ['data', 'entity', 'otherAddressesInLocalLanguage', 'otherAddress'],
    []
  );

  // Add local language addresses fields to other addresses
  legalAddressInLocalLanguage && otherAddresses.push(legalAddressInLocalLanguage);
  headquartersAddressInLocalLanguage && otherAddresses.push(headquartersAddressInLocalLanguage);

  _.set(payload[DS_NAME], ['data', 'entity', 'otherAddressesInLocalLanguage', 'otherAddress'], otherAddresses);

  // Remove local language addresses fields
  legalAddressInLocalLanguage && delete payload[DS_NAME].data.entity.legalAddressInLocalLanguage;
  headquartersAddressInLocalLanguage && delete payload[DS_NAME].data.entity.headquartersAddressInLocalLanguage;
};

/* istanbul ignore next */
export function postprocess(payload) {
  trimStrings(payload);

  const parents = [...CONSOLIDATED_PARENTS, ...BRANCH_PARENT, ...FUND_PARENTS];

  parents.forEach(parent => {
    mutateAccountingStandards(payload, parent);
  });

  removeFundParentsWhenEntityIsNotFund(payload);

  removeDummyFundSubCategory(payload);

  removeDummyBranchFields(payload);

  removeDummyNoCreationDateIsAvailable(payload);

  cleanManagedFundParent(payload);

  cleanConsolidatedParentsInfo(payload);

  moveDummyLanguageAddressesToOtherAddresses(payload);

  return payload;
}
