import angular from 'angular';
import { ACCESS_HEADER } from '../../common/const/access-level';

const __module__ = 'appResolvers',
  app = angular.module(__module__, []),
  { factory, provider } = app;

factory('makeOptionalAllowRejection', [
  '$q',
  function($q) {
    return function makeOptionalAllowRejection(allowRejection) {
      return function optionalAllowRejection(rej) {
        return allowRejection ? $q.resolve(null) : $q.reject(rej);
      };
    };
  }
]);

provider('ErrorMessageResolver', function() {
  var self = this;

  self.resolve = function() {
    return {
      message: [
        '$route',
        function($route) {
          return $route.current.params.message;
        }
      ],
      prev: [
        '$route',
        function($route) {
          return $route.current.params.prev;
        }
      ]
    };
  };

  self.$get = function() {
    return self;
  };
});

provider('UserSubmissionDataViewerResolver', function() {
  var self = this;

  self.resolve = function() {
    return {
      label: function() {
        return 'Submission';
      },
      humanId: [
        '$route',
        function($route) {
          return $route.current.params.humanId;
        }
      ],
      id: [
        '$route',
        function($route) {
          return $route.current.params.id;
        }
      ],
      data: [
        '$route',
        'UserService',
        function($route, UserService) {
          var submissionId = $route.current.params.id;

          return UserService.getLeiSubmission(submissionId).then(function(res) {
            return res && res.data && res.data.data;
          });
        }
      ]
    };
  };

  self.$get = function() {
    return self;
  };
});

provider('LeiPublicDataViewerResolver', function() {
  var self = this;

  self.resolve = function() {
    return {
      label: function() {
        return 'LEI';
      },
      humanId: function() {
        return null;
      },
      id: [
        '$route',
        function($route) {
          return $route.current.params.id;
        }
      ],
      data: [
        '$route',
        'LeiService',
        'LeiDataEnricher',
        'DefaultErrorHandler',
        function($route, LeiService, LeiDataEnricher, DefaultErrorHandler) {
          var leiId = $route.current.params.id;

          // ENG4LEI-962
          // when loading public funds, we should check the umbrella fund and
          // display it with the umbrella fund name attached up front
          return LeiService.getPublicLeiData(leiId)
            .then(function(res) {
              const leiData = res && res.data && res.data.data;
              return leiData ? LeiDataEnricher.enrichPublicLeiData(leiData) : leiData;
            })
            .catch(DefaultErrorHandler);
        }
      ]
    };
  };

  self.$get = function() {
    return self;
  };
});

provider('GleifDataViewerResolver', function() {
  var self = this;

  self.resolve = function() {
    return {
      label: function() {
        return 'LEI';
      },
      humanId: function() {
        return null;
      },
      id: [
        '$route',
        function($route) {
          return $route.current.params.id;
        }
      ],
      data: [
        '$route',
        'LeiService',
        'LeiDataEnricher',
        function($route, LeiService, LeiDataEnricher) {
          var leiId = $route.current.params.id;

          return LeiService.getGleifLei(leiId).then(function(res) {
            const leiData = res && res.data && res.data.data;
            return leiData ? LeiDataEnricher.enrichPublicLeiData(leiData) : leiData;
          });
        }
      ]
    };
  };

  self.$get = function() {
    return self;
  };
});

provider('AnyLeiDataViewerResolver', function() {
  var self = this;

  self.resolve = function() {
    return {
      label: function() {
        return 'LEI';
      },
      humanId: function() {
        return null;
      },
      id: [
        '$route',
        function($route) {
          return $route.current.params.id;
        }
      ],
      data: [
        '$route',
        'LeiService',
        'DefaultErrorHandler',
        function($route, LeiService, DefaultErrorHandler) {
          var leiId = $route.current.params.id;

          return LeiService.getPublicLeiDataFromAllSource(leiId)
            .then(function(res) {
              return res && res.data && res.data.data;
            })
            .catch(DefaultErrorHandler);
        }
      ]
    };
  };

  self.$get = function() {
    return self;
  };
});

provider('DownloadsResolver', function() {
  var self = this;

  self.resolve = function() {
    return {
      leiXml: [
        'LeiService',
        function(LeiService) {
          return LeiService.getLeiXml().then(function(res) {
            return res && res.data;
          });
        }
      ]
    };
  };

  self.$get = function() {
    return self;
  };
});

provider('AuthResolver', function() {
  var self = this;

  // do not need response.data
  self.resolve = function() {
    return {
      _auth: [
        'SecurityService',
        function(SecurityService) {
          return SecurityService.isLoggedIn();
        }
      ]
    };
  };

  self.$get = function() {
    return self;
  };
});

provider('InjectStripeJSResolver', function() {
  var self = this;

  self.resolve = function() {
    return {
      injectStripeJS: [
        'CardService',
        function(CardService) {
          return CardService.injectStripeJS();
        }
      ]
    };
  };

  self.$get = function() {
    return self;
  };
});

provider('UserResolver', function() {
  var self = this;

  self.resolve = function(options) {
    var opts = options || {};

    return {
      userProfile: [
        'UserService',
        'makeOptionalAllowRejection',
        function(UserService, makeOptionalAllowRejection) {
          return UserService.getUserProfile().then(function(res) {
            return res && res.data;
          }, makeOptionalAllowRejection(opts.allowRejection));
        }
      ]
    };
  };

  self.$get = function() {
    return self;
  };
});

provider('AccessLevelResolver', function() {
  var self = this;

  self.resolve = function() {
    return {
      _accessLevel: [
        'SecurityService',
        function(SecurityService) {
          return SecurityService.checkAccessLevel().then(function(res) {
            return res && res.headers(ACCESS_HEADER);
          });
        }
      ]
    };
  };

  self.$get = function() {
    return self;
  };
});

provider('AdminRouteResolver', function() {
  var self = this;

  self.resolve = function() {
    return {
      _adminRoute: [
        'AdminService',
        'DefaultErrorHandler',
        function(AdminService, DefaultErrorHandler) {
          return AdminService.ping().catch(DefaultErrorHandler);
        }
      ]
    };
  };

  self.$get = function() {
    return self;
  };
});

provider('AdminRoleResolver', function() {
  var self = this;

  self.resolve = function() {
    return {
      _admin: [
        'SecurityService',
        function(SecurityService) {
          return SecurityService.isAdmin();
        }
      ]
    };
  };

  self.$get = function() {
    return self;
  };
});

provider('UserRoleResolver', function() {
  var self = this;

  self.resolve = function() {
    return {
      _user: [
        'SecurityService',
        function(SecurityService) {
          return SecurityService.isUser();
        }
      ]
    };
  };

  self.$get = function() {
    return self;
  };
});

provider('SendUnlockAccountCodeResolver', function() {
  var self = this;

  self.resolve = function() {
    return {
      username: [
        '$route',
        function($route) {
          return $route.current.params.username;
        }
      ],
      _sendUnlockAccountCode: [
        '$route',
        'UserService',
        function($route, UserService) {
          var username = $route.current.params.username;
          var email = $route.current.params.email;

          return UserService.sendUnlockAccountCode(username, email);
        }
      ]
    };
  };

  self.$get = function() {
    return self;
  };
});

import { OPEN } from '../../common/const/access-level';
provider('LogoutResolver', function() {
  var self = this;

  self.resolve = function() {
    return {
      _logout: [
        'UserService',
        'AccessLevel',
        function(UserService, AccessLevel) {
          return UserService.logout().then(function() {
            AccessLevel.change(OPEN);
          });
        }
      ]
    };
  };

  self.$get = function() {
    return self;
  };
});

provider('AccountEmailsResolver', function() {
  var self = this;

  self.resolve = function(options) {
    var opts = options || {};

    return {
      loginEmail: [
        'UserService',
        'makeOptionalAllowRejection',
        function(UserService, makeOptionalAllowRejection) {
          return UserService.getUserInfo().then(function(res) {
            return res && res.data.email;
          }, makeOptionalAllowRejection(opts.allowRejection));
        }
      ],
      accountEmails: [
        'UserService',
        'makeOptionalAllowRejection',
        function(UserService, makeOptionalAllowRejection) {
          return UserService.getAccountEmails().then(function(res) {
            return res && res.data;
          }, makeOptionalAllowRejection(opts.allowRejection));
        }
      ]
    };
  };

  self.$get = function() {
    return self;
  };
});

provider('RecaptchaResolver', function() {
  var self = this;

  self.resolve = function() {
    return {
      recaptchaResult: [
        'PublicStorageService',
        function(PublicStorageService) {
          return PublicStorageService.getSiteKeys().then(function(res) {
            return res && res.data;
          });
        }
      ]
    };
  };

  self.$get = function() {
    return self;
  };
});

export { __module__ as default };
